import React from 'react';
import { Button, ButtonGroup, Tooltip, Text } from '@nike/eds';
import { UploadQueueTable } from './upload_queue_table';
import useColorScheme from '../../hooks/use_color_scheme';

export function UploadQueue({
  handleFileImport,
  fileQueue,
  handleSelectAll,
  selectAll,
  handleClearAll,
  handleUpload,
  fileToFileRow,
  selectedFiles,
}) {
  const { appearance } = useColorScheme();
  // check if all files in the queue are valid via them having an asset
  const queueIsValid = selectedFiles.every(file => fileQueue[file].asset);
  const hasSelectedFiles = selectedFiles.length > 0;
  const disableUpload = !queueIsValid || !hasSelectedFiles;

  const disabledMessaging = !queueIsValid
    ? 'At least one of your selected files is not a valid file to upload.  Please deselect or replace these files.'
    : 'You have no selected files.  Please select at least one file.';

  return (
    <div className="upload-queue__container eds-spacing--mb-36">
      <UploadQueueTable
        onImportFiles={handleFileImport}
        fileQueue={fileQueue?.map(fileToFileRow)}
        handleSelectAll={handleSelectAll}
        selectAll={selectAll}
      />
      {fileQueue.length ? (
        <ButtonGroup className="queue-actions eds-spacing--mt-16">
          <Button
            size="small"
            disabled={!hasSelectedFiles}
            variant="secondary"
            onClick={handleClearAll}
          >
            Clear Selected
          </Button>
          {/* only show the tooltip if this is disabled so we can provide better messaging to the end user */}
          {disableUpload ? (
            <Tooltip
              test-id="upload-tooltip"
              isDark={appearance === 'dark' ? false : true}
              bodySlot={
                <Text style={{ width: '156px', lineHeight: '23px' }}>{disabledMessaging}</Text>
              }
              placement="top"
              delay={0}
              className="queue-actions"
            >
              <Button
                onClick={handleUpload}
                size="small"
                disabled={disableUpload}
                variant="primary"
              >
                Upload Selected
              </Button>
            </Tooltip>
          ) : (
            <Button onClick={handleUpload} size="small" disabled={disableUpload} variant="primary">
              Upload Selected
            </Button>
          )}
        </ButtonGroup>
      ) : null}
    </div>
  );
}
