import { Link, useLoaderData, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useAutoSections } from '../../hooks/use_auto_sections';
import { useCustomSections } from '../../hooks/use_custom_sections';
import { useState, useEffect, useRef } from 'react';
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import DNDSection from './dnd_section';
import { Header } from '../header';
import EmptyBoard from '../empty_board/empty_board';
import { MaterialCollectionModals } from '../material_collection/material_collection_modals';
import { useSelectStore } from '../../hooks/use_select';
import { useModalStore } from '../../hooks/use_modal';
import { useExportStore } from '../../hooks/use_export';
import { useSectionGroups } from '../../hooks/use_section_groups';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { update_board } from '../../hooks/use_boards';
import { SectionSidePanel } from '../sections_side_panel/sections_side_panel';
import { MaterialImage, MaterialImagePlaceholder } from './material_image';
import { DisplaySettings } from '../display_settings';
import { CARDACTIONS } from '../../utils/cardactions';
import { useDragSelect } from '../drag_select';
import { useToteStore } from '../../hooks/use_tote';
import { useFeaturesStore } from '../../hooks/use_features';
import { add_materials_to_boards } from '../../utils/add_materials_to_boards';
import { useNotificationsStore } from '../../hooks/use_notifications';
import useColorScheme from '../../hooks/use_color_scheme';
import './board.css';
import { OtherMaterials } from './other_materials';
import { LibrarySortsSidePanel } from '../library_sorts_side_panel';
import { FilterList } from '../filter_list';
import { useLibraryFilterStore } from '../../hooks/use_library_filters';
import { useLibrarySortsStore } from '../../hooks/use_library_sorts';
import { useLibrarySubTypeFacets } from '../../hooks/use_materials';
import { useFacetStore } from '../../hooks/use_facets';
import { MaterialSnackbar } from './material_snackbar';
import { useDisplaySettingsStore } from '../../hooks/use_display_settings';

function matchingMaterial(target) {
  return material => {
    return target.id === material.id;
  };
}

class MyPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: 'onPointerDown',
      handler: ({ nativeEvent: event }) => {
        if (!event.isPrimary || event.button !== 0 || isInteractiveElement(event.target)) {
          return false;
        }

        return true;
      },
    },
  ];
}

function isInteractiveElement(element) {
  // adding this span allows us to still interact with the card action items
  const interactiveElements = ['button', 'input', 'textarea', 'select', 'option', 'span'];

  if (interactiveElements.includes(element.tagName.toLowerCase())) {
    return true;
  }

  return false;
}

export default function Board({ title }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState('general');
  const [disableFilters, setDisableFilters] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [showDisplaySettings, setShowDisplaySettings] = useState(false);
  const [showSorts, setShowSorts] = useState(false);
  const [showSectionsPanel, setShowSectionsPanel] = useState(false);
  const [showManageBoard, setShowManageBoard] = useState(false);
  const [shouldUpdateBoard, setShouldUpdateBoard] = useState(false);
  const [currentMaterial, setCurrentMaterial] = useState(null);
  const [isAddToBoardOpen, setIsAddToBoardOpen] = useState(false);
  const [showNewBoardModal, setShowNewBoardModal] = useState(false);
  const [boardMutationResponse, setBoardMutationResponse] = useState();
  const [entries, setEntries] = useState([]);
  // sub_type_facet_params is used to dynamically update the sub type facet options in the filters
  const [subTypeFacetParams, setSubTypeFacetParams] = useState(null);
  const card_element = useRef(null);
  const { send_notification, dismiss_notification } = useNotificationsStore();
  const { set_library_facets } = useFacetStore();
  const library_filter_store = useLibraryFilterStore();
  const { get_sort_params, load_sorts_from_search_params } = useLibrarySortsStore();
  const {
    list,
    get_filter_params,
    load_filters_from_search_params,
    load_chips,
    update_filter_value,
  } = library_filter_store;
  const { view_type } = useDisplaySettingsStore();

  const { features } = useFeaturesStore();

  const { add_material_to_tote, set_show_tote_modal } = useToteStore();

  const { activeGroup, setActiveGroup, expandedSections, expandSection, foldSection } =
    useSectionGroups();

  const [showMaterial, setShowMaterial] = useState(false);
  const [showSnack, setShowSnack] = useState(false);

  const ds = useDragSelect();
  const [board, setBoard] = useState(useLoaderData());
  const navigate = useNavigate();
  const location = useLocation();
  const { appearance } = useColorScheme();

  const { set_active_modal, set_modal_data, set_trigger_location } = useModalStore();
  const { set_export_materials, set_export_all, set_total_materials, set_palette_id, set_source } =
    useExportStore();

  const { multi_select, set_multi_select, update_selected_materials, selected_materials } =
    useSelectStore();

  const {
    initializeAutoSections,
    getAutoSections,
    getAutoSectionItems,
    getActiveAutoSections,
    getAutoSectionsForPost,
    getActiveSectionType,
    setActiveSectionType,
    getAutoSectionedMaterials,
    setAutoSectionedMaterials,
    removeAutoMaterial,
  } = useAutoSections();

  const {
    sectionedMaterials,
    customSections,
    removeMaterial,
    initializeCustomSections,
    getCustomSectionsForPost,
    getCustomSectionItems,
    getCustomSections,
    setCustomSectionMaterials,
  } = useCustomSections();
  // TODO: test that it's reactive once dissolve sections is working
  const showSections =
    board?.activeSectionsType &&
    // if there are custom sections and the board is set to custom sections
    ((activeGroup === 'custom_sections' && customSections?.length > 0) ||
      // if there are auto sections and the board is set to auto sections
      (activeGroup === 'auto_sections' &&
        getActiveSectionType() &&
        getActiveAutoSections()?.length > 0)) // easiest way to check if there are auto sections is if there are non-custom ones
      ? true
      : false;
  const [hydrated, setHydrated] = useState(false);

  const updateBoardData = () => {
    const customSectionData = getCustomSectionsForPost();

    const autoSectionsData = getAutoSectionsForPost();

    let activeSectionsType = 'default';
    if (activeGroup === 'custom_sections') {
      activeSectionsType = 'custom';
    } else if (activeGroup === 'auto_sections' && getActiveSectionType()) {
      activeSectionsType = getActiveSectionType();
    }

    // const autoSections =
    update_board({
      ...board,
      ids: entries.map(material => material?.id),
      palette_id: board.paletteId,
      sections: [...customSectionData, ...autoSectionsData],
      activeSectionsType,
    });
    setShouldUpdateBoard(false);
  };

  const hydrateCustomSections = () => {
    initializeCustomSections(board.materials, board?.sections || []);
    getCustomSections().forEach(customSection => {
      expandSection(customSection.id);
    });
  };

  // iniitalize auto sections store with sections that already exist on the palette_summary
  // or create new ones if those dont exist
  const hydrateAutoSections = () => {
    initializeAutoSections(board.materials, board?.sections || []);
    getAutoSections().forEach(autoSection => {
      expandSection(autoSection.id);
    });
  };

  // boardMutationResponse is triggered when the board is updated via manage boards modal
  // so to show those updated changes we can just trigger navigate to the same board
  // and the data loader for the route will fetch the new data for us
  useEffect(() => {
    if (boardMutationResponse) {
      setBoard(boardMutationResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardMutationResponse]);

  // disable filters based on if there are custom sections or auto sections
  // can probably refactor this to be more efficient and listen to showSections or something
  useEffect(() => {
    if (
      (activeGroup === 'custom_sections' && customSections?.length > 0) ||
      (activeGroup === 'auto_sections' &&
        getActiveSectionType() &&
        getActiveAutoSections()?.length > 0)
    ) {
      setDisableFilters(true);
    } else {
      setDisableFilters(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeGroup]);

  // load filters from the search params and set them in the filter store
  // when we set the params that will triger the useMaterialsHook to run loading in new materials
  useEffect(() => {
    const filter_params = get_filter_params();
    const sort_params = get_sort_params();
    const params = { ...filter_params, ...sort_params };
    // remove certain parameters from the window url
    delete params['fields.includes'];
    delete params['size'];
    delete params['from'];
    delete params['active'];

    setSubTypeFacetParams(params?.materialType4 ? { materialType4: params?.materialType4 } : null);
    setSearchParams(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    list, // runs when the filters change
    showSorts, // runs when the sorts change

    // TODO: validate that setting filters and sorts in the search params triggers the right data to load
    // we probably need to listen to the search params and set it to the filter/sort stores since that's
    // what triggers the data to load in the router data loader
    searchParams, // runs when the search params change
    load_filters_from_search_params,
    load_sorts_from_search_params,
  ]);

  // TODO: double check that this works
  // whenever the search params change we need to dynamically update facet options
  const subTypeFacetsHook = useLibrarySubTypeFacets({ ...subTypeFacetParams });

  useEffect(() => {
    const facets_data = subTypeFacetsHook?.data;

    if (facets_data) {
      set_library_facets(facets_data);
    }
  }, [subTypeFacetsHook?.data, set_library_facets]);

  // below are the useEffects we need to make sure select & multi select work properly since they use the
  // drag select pub/sub pattern
  useEffect(() => {
    if (multi_select) {
      setShowSnack(true);
    }
  }, [multi_select]);
  useEffect(() => {
    if (ds) {
      // set initial state for drag select on load
      const addSelectedMaterialsToDS = (selected_materials, attempt = 0) => {
        const material_card_elements = selected_materials?.map(material_id => {
          const material_card_element = document.getElementById(`${material_id}`);
          return material_card_element;
        });

        if (material_card_elements?.includes(undefined) || material_card_elements?.includes(null)) {
          if (attempt < 5) {
            setTimeout(() => {
              addSelectedMaterialsToDS(selected_materials, attempt + 1);
            }, 50);
          }
        } else {
          ds.setSelection(material_card_elements, false);
        }
      };

      addSelectedMaterialsToDS(selected_materials);
    }
  }, [ds, selected_materials]);
  // adding a selectable element
  useEffect(() => {
    const element = card_element.current;

    if (!element || !ds) return;
    ds.addSelectables(element);
  }, [ds, card_element]);
  // subscribing to a callback
  useEffect(() => {
    const id = ds?.subscribe('DS:end', e => {
      const ids = [...e?.items?.map(item => item?.id)];

      update_selected_materials(ids);
    });

    return () => ds?.unsubscribe('DS:end', null, id);
  }, [ds, update_selected_materials, selected_materials]);

  if (entries.length === 0 && board.materials?.length > 0) {
    setEntries(board.materials);
    initializeAutoSections(board.materials);
  }

  if (!hydrated) {
    setEntries(board?.materials);
    hydrateAutoSections();
    hydrateCustomSections();
    if (board?.activeSectionsType !== 'default' && board?.activeSectionsType !== 'custom') {
      setActiveGroup('auto_sections');
      setActiveSectionType(board?.activeSectionsType);
    } else {
      setActiveGroup(board?.activeSectionsType === 'custom' ? 'custom_sections' : 'default');
    }
    setHydrated(true);
  }

  if (shouldUpdateBoard) {
    updateBoardData();
  }

  // DND API
  const handleDragStart = event => {
    const draggedMaterial = event.active?.data?.current?.material;
    setCurrentMaterial(draggedMaterial);
  };

  const handleDragEnd = event => {
    if (activeGroup === 'auto_sections' && getActiveSectionType()) {
      handleDragEndAuto(event);
    } else if (activeGroup === 'custom_sections' && customSections?.length) {
      handleDragEndCustom(event);
    } else {
      handleDragEndDefault(event);
    }
  };

  const handleDragEndDefault = event => {
    const dropTarget = event?.over?.data?.current;
    const dragTarget = event?.active?.data?.current;

    if (!dropTarget) return;

    const oldIndex = entries.findIndex(matchingMaterial(dragTarget));
    const newIndex = entries.findIndex(matchingMaterial(dropTarget));

    const newArr = arrayMove(entries, oldIndex, newIndex);
    setEntries(newArr);
    setShouldUpdateBoard(true);
  };

  const handleDragEndCustom = event => {
    const dropTarget = event.over?.data?.current;
    const dragTarget = event.active?.data?.current;

    if (!dropTarget) return;

    const isDraggingUnsectioned = dragTarget?.sectionId.includes('unsectioned');
    const isOverUnsectioned = dropTarget?.sectionId.includes('unsectioned');

    const dragTargetSectionMaterials = isDraggingUnsectioned
      ? entries
      : getCustomSectionItems(dragTarget.sectionId);
    const dropTargetSectionMaterials = isOverUnsectioned
      ? entries
      : getCustomSectionItems(dropTarget.sectionId);

    const isOverCustomSection = !dropTarget?.sectionId.includes('unsectioned');

    const oldIndex = dragTargetSectionMaterials.findIndex(matchingMaterial(dragTarget));
    const newIndex = dropTargetSectionMaterials.findIndex(matchingMaterial(dropTarget));

    function getNewSectionMaterials() {
      const newArr = [];
      if (dropTargetSectionMaterials?.length > 0) {
        newArr.push(...dropTargetSectionMaterials);

        // remove the material from the array before readding it to the correct location   if it already existed in the section
        const matchingIndex = newArr.findIndex(matchingMaterial(dragTarget));
        if (matchingIndex > -1) {
          newArr.splice(matchingIndex, 1);
        }

        newArr.splice(newIndex === -1 ? newArr.length : newIndex, 0, dragTarget.material);
      } else {
        newArr.push(dragTarget.material);
      }
      return newArr;
    }

    if (isDraggingUnsectioned) {
      if (isOverUnsectioned) {
        // draggin unsectioned material to section
        const newArr = arrayMove(dropTargetSectionMaterials, oldIndex, newIndex);
        setEntries(newArr);
        return;
      } else {
        // draggin unsectioned within unsectioned materials
        const newArr = getNewSectionMaterials();
        setCustomSectionMaterials(dropTarget.sectionId, newArr);
        setShouldUpdateBoard(true);
        return;
      }
    } else {
      // dragging sectioned material

      if (dragTarget.sectionId !== dropTarget.sectionId) {
        // remove draggin material from the section it was in
        setCustomSectionMaterials(
          dragTarget.sectionId,
          dragTargetSectionMaterials.filter(material => material.id !== dragTarget.id)
        );
      }

      if (isOverCustomSection) {
        // dragging sectioned material to another section
        const newArr = getNewSectionMaterials();
        setCustomSectionMaterials(dropTarget.sectionId, newArr);
        setTimeout(() => expandSection(dropTarget.sectionId), 200);
      } else {
        // dragging sectioned material to unsectioned materials
        const newArr = arrayMove(entries, oldIndex, newIndex);
        setEntries(newArr);
      }
      setShouldUpdateBoard(true);
    }
  };

  const handleDragEndAuto = event => {
    const dropTarget = event.over?.data?.current;
    const dragTarget = event.active?.data?.current;

    // dont allow users to drag materials between different auto sections
    if (!dropTarget || dropTarget.sectionId !== dragTarget.sectionId) return;

    const materials = getAutoSectionedMaterials(dropTarget.sectionId);

    const oldIndex = materials.findIndex(matchingMaterial(dragTarget));
    const newIndex = materials.findIndex(matchingMaterial(dropTarget));

    const newArr = arrayMove(materials, oldIndex, newIndex);
    setAutoSectionedMaterials(dropTarget.sectionId, newArr);
    setShouldUpdateBoard(true);
  };

  const handleDragMove = e => {
    const dropTarget = e.over?.data?.current;
    const dragTarget = e.active?.data?.current;

    if (dropTarget && dropTarget.sectionId && dropTarget.sectionId !== dragTarget.sectionId) {
      expandSection(dropTarget.sectionId);
    }
  };

  const pointerSensor = useSensor(MyPointerSensor, {
    activationConstraint: {
      distance: 8,
    },
  });
  const sensors = useSensors(
    pointerSensor,
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  ////

  // material card actions API
  const header_actions = {
    show_filter: () => {
      setShowFilters(!showFilters);
      setShowMaterial(false);
    },
    show_display_settings: () => {
      setShowDisplaySettings(!showDisplaySettings);
      setShowMaterial(false);
    },
    show_sorts: () => {
      setShowSorts(!showSorts);
      setShowMaterial(false);
    },
    handle_settings: () => {
      setShowManageBoard(!showManageBoard);
    },
    toggle_multi_select: () => {
      set_multi_select(!multi_select);
      update_selected_materials([]);
    },
    disable_filters: () => {
      setDisableFilters(true);
    },
    export_all: () => {
      set_active_modal('export');
      set_export_materials(entries);
      set_export_all(true);
      set_total_materials(entries.length);
      set_palette_id(board.id);
      set_source('DPC');
      set_trigger_location('exportAll');
    },
    handle_sections: () => {
      setShowSectionsPanel(!showSectionsPanel);
      setShowMaterial(false);
    },
  };

  function openMaterial(id) {
    if (features?.material_details_panel) {
      if (!showMaterial) {
        setShowMaterial(true);
      }
    }
  }

  const handle_go_to_board = (e, board) => {
    e.stopPropagation();
    dismiss_notification();
    navigate(`/boards/${board?.paletteId}`);
  };

  ////

  const onClickSectionTab = sectionId => {
    if (expandedSections.includes(sectionId)) {
      foldSection(sectionId);
    } else {
      expandSection(sectionId);
    }
  };

  const allSectionedMaterials = Array.from(sectionedMaterials.values()).flat();
  const unsectionedEntries = entries.filter(entry => {
    return !allSectionedMaterials.find(sectionedMaterial => sectionedMaterial.id === entry.id);
  });

  // adds material to the DS:end event so we can select via the pub/sub pattern
  function selectMaterial(material_id) {
    const material_card_element = document.getElementById(`${material_id}`);

    ds.addSelection(material_card_element);
    ds.publish('DS:end', { items: [{ id: material_id }] });
  }

  const handleSectionUpdate = (itemId, type, payload) => {
    switch (type) {
      case CARDACTIONS.SELECT:
        selectMaterial(itemId);
        break;

      case CARDACTIONS.MOVETOSECTION:
        if (payload.from) {
          const fromMaterials = getCustomSectionItems(payload.from);
          setCustomSectionMaterials(
            payload.from,
            fromMaterials.filter(item => item.id !== itemId)
          );
        }

        expandSection(payload.to);
        setCustomSectionMaterials(payload.to, [
          ...getCustomSectionItems(payload.to),
          entries.find(matchingMaterial({ id: itemId })),
        ]);
        break;

      case CARDACTIONS.MULTISELECT:
        set_multi_select(true);
        break;

      case CARDACTIONS.ADDTOTOTE:
        const res = add_material_to_tote(itemId);
        return res;

      case CARDACTIONS.OPEN:
        openMaterial(itemId);
        break;

      case CARDACTIONS.ADDTO:
        set_active_modal('add_to');
        set_trigger_location('materialCardMenuModal');
        set_modal_data({ materials: [itemId] });
        break;

      case CARDACTIONS.ADDTOBOARD:
        add_materials_to_boards({
          boards: [payload],
          material_ids: [itemId],
          trigger_location: 'material_card',
        })
          .then(() => {
            send_notification(
              'success',
              'Material Added to Board',
              () => dismiss_notification(),
              <>
                <Link onClick={e => handle_go_to_board(e, board)}>Go to Board</Link>
              </>
            );
          })
          .catch(error => {
            console.error(error);
            send_notification('error', 'Error Adding Material to Board');
          });
        break;

      case CARDACTIONS.EXPORT:
        set_active_modal('export');
        set_export_materials([payload]);
        set_trigger_location('materialCardMenu');
        set_total_materials(1);
        break;

      case CARDACTIONS.OPENTOTE:
        set_show_tote_modal(true);
        break;

      case CARDACTIONS.REMOVE:
        const newEntries = entries.filter(item => item.id !== payload);
        customSections.map(customSection => removeMaterial(itemId, customSection.id));
        getAutoSections().map(section => removeAutoMaterial(itemId, section.id));
        setEntries(newEntries);
        break;

      default:
        console.log('default handler');
    }

    setShouldUpdateBoard(true);
  };
  function remove_selections() {
    update_selected_materials([]);
    set_multi_select(false);
    setShowSnack(false);
  }
  const has_user_permissions =
    board?.currentUserAccess?.includes('WRITE') || board?.currentUserAccess?.includes('ADMIN');

  return (
    <div className={`board-container ${appearance === 'dark' ? 'eds--dark' : 'eds--light'}`}>
      <Header
        title={title}
        palette_data={board}
        go_back={() => navigate('/boards')}
        previous_route="Boards"
        current_route={board.name}
        total={entries?.length}
        actions={header_actions}
        multi_select={multi_select}
        filters={disableFilters ? [] : load_chips()}
        filter_store={library_filter_store}
        update_filter_value={update_filter_value}
        disable_filters={true}
        show_search={false}
        icon_row={{
          select: true,
          display_settings: true,
          sections: board?.currentUserAccess?.includes('WRITE'),
          settings: board?.currentUserAccess?.includes('ADMIN'),
        }}
      />
      <MaterialCollectionModals
        has_user_permissions={has_user_permissions}
        materials={entries}
        is_add_to_board_open={isAddToBoardOpen}
        set_is_add_to_board_open={setIsAddToBoardOpen}
        is_new_board={!board?.has_materials} // might need to change this
        // empty board modal
        show_new_board_modal={showNewBoardModal}
        set_show_new_board_modal={setShowNewBoardModal}
        // manage board modal
        show_manage_board={showManageBoard}
        palette_summary={board}
        set_show_manage_board={setShowManageBoard}
        palette_name={board?.name}
        board_mutation_response={boardMutationResponse}
        set_board_mutation_response={setBoardMutationResponse}
      />
      {board?.has_materials ? (
        <DndContext
          sensors={sensors}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragMove={handleDragMove}
        >
          <div className="entries" id="multi_select_area">
            {showSections ? (
              <div>
                {/* auto sections */}
                {activeGroup === 'auto_sections' &&
                  getActiveSectionType() &&
                  getActiveAutoSections().map((autoSection, rowIndex) => (
                    <DNDSection
                      key={autoSection.id}
                      id={autoSection.id}
                      sectionIndex={rowIndex}
                      shouldExpand={expandedSections.includes(autoSection.id)}
                      label={autoSection.label}
                      items={getAutoSectionItems(autoSection.id)}
                      onClick={onClickSectionTab}
                      onChange={handleSectionUpdate}
                      enableEdit={board?.currentUserAccess?.includes('WRITE')}
                      selectRef={card_element}
                    />
                  ))}
                {/* custom sections */}
                {activeGroup === 'custom_sections' &&
                  customSections.map((customSection, rowIndex) => (
                    <DNDSection
                      key={customSection.id}
                      id={customSection.id}
                      sectionIndex={rowIndex}
                      label={customSection.label}
                      shouldExpand={expandedSections.includes(customSection.id)}
                      items={getCustomSectionItems(customSection.id)}
                      onClick={onClickSectionTab}
                      onChange={handleSectionUpdate}
                      enableMoveToSection={board?.currentUserAccess?.includes('WRITE')}
                      enableEdit={board?.currentUserAccess?.includes('WRITE')}
                      selectRef={card_element}
                    />
                  ))}
                {activeGroup === 'custom_sections' && (
                  <OtherMaterials
                    id="unsectioned"
                    sectionIndex={customSections.length + 1}
                    shouldExpand={expandedSections.includes('unsectioned')}
                    label="Other Materials"
                    items={unsectionedEntries} // fix for auto
                    onClick={onClickSectionTab}
                    onChange={handleSectionUpdate}
                    enableMoveToSection={board?.currentUserAccess?.includes('WRITE')}
                    enableEdit={board?.currentUserAccess?.includes('WRITE')}
                    selectRef={card_element}
                  />
                )}
              </div>
            ) : (
              // default board experience has no sections but still has the ability to drag and drop
              <OtherMaterials
                id="unsectioned"
                sectionIndex={customSections.length + 1}
                shouldExpand={expandedSections.includes('unsectioned')}
                items={entries || []}
                onClick={onClickSectionTab}
                onChange={handleSectionUpdate}
                enableEdit={board?.currentUserAccess?.includes('WRITE')}
                selectRef={card_element}
              />
            )}
          </div>
          <DragOverlay>
            {currentMaterial ? (
              // change to full card
              <MaterialImage material={currentMaterial} view_type={view_type} />
            ) : (
              <MaterialImagePlaceholder width={26} height={26} />
            )}
          </DragOverlay>
        </DndContext>
      ) : (
        <EmptyBoard hasWriteAccess={has_user_permissions} />
      )}

      <SectionSidePanel
        show={showSectionsPanel}
        set_show={setShowSectionsPanel}
        set_palette_sumary={() => {}}
        materials={entries}
        onBoardNeedsUpdate={() => setShouldUpdateBoard(true)}
      />
      <DisplaySettings
        compare_table_data={false}
        show_display_settings={showDisplaySettings}
        set_show_display_settings={setShowDisplaySettings}
      />
      <LibrarySortsSidePanel show_sorts={showSorts} set_show_sorts={setShowSorts} />
      <FilterList
        active_tab={activeTab}
        set_active_tab={setActiveTab}
        show_filters={showFilters}
        set_show_filters={setShowFilters}
      />
      <MaterialSnackbar
        dark={appearance === 'dark'}
        selected_materials={entries.filter(material => selected_materials.includes(material?.id))}
        remove_selections={remove_selections}
        show_snack={showSnack}
        enable_move_to={
          board?.currentUserAccess?.includes('WRITE') &&
          activeGroup === 'custom_sections' &&
          customSections?.length > 0
        }
        onMoveToSectionFinished={() => {
          update_selected_materials([]);
          setShowSnack(false);
          setShouldUpdateBoard(true);
        }}
        actions={{
          add_to: () => {
            set_active_modal('add_to');
            set_trigger_location('multiSelectSnackbar');
            set_modal_data({ materials: selected_materials, on_success: remove_selections });
          },
        }}
        // TODO: might need to rethink previous_route for palettes/boards
        compare_materials={event => {
          event.stopPropagation();
          navigate(`/compare?ids=${selected_materials?.toString()}`, {
            state: {
              id: selected_materials,
              previous: { ...location, name: board?.name },
              previous_route:
                title?.toLowerCase() === 'material library' ? 'library' : title?.toLowerCase(),
            },
          });
        }}
      />
    </div>
  );
}
