import { Text, Card, Button, MenuItem } from '@nike/eds';
import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { MaterialImage } from './material_image';
import three_dimensional_icon from '../../assets/3d.svg';
import './styles/material_card.css';

import { ColorWindowPebbleRange } from './color_window_pebble_range';
import { useDisplaySettingsStore } from '../../hooks/use_display_settings';
import { useFeaturesStore } from '../../hooks/use_features';
import useColorScheme from '../../hooks/use_color_scheme';
import { MaterialActions } from './material_actions/material_actions';
import { useModalStore } from '../../hooks/use_modal';
import { useNotificationsStore } from '../../hooks/use_notifications';
import { use_engine_store } from '../../hooks/use_engine';
import { FixedContextMenu } from './fixed_context_menu';
import { track } from '../../utils/analytics';
import { get_secondary_material_id } from '../../utils/get_secondary_material_id';
import HoverText from './hover_text';

export function MaterialCard({
  material,
  selected,
  multi_select,
  actions,
  active,
  set_active_card,
  palette_edit_access,
  show_card_actions,
  onUpdateMaterial,
  material_body = ({ material_data, material, on_click }) => (
    <MaterialCardBody material={material} material_data={material_data} on_click={on_click} />
  ),
}) {
  const { view_type, material_data, size } = useDisplaySettingsStore();
  const { appearance } = useColorScheme();
  const { features } = useFeaturesStore();
  const { set_active_modal, set_modal_data } = useModalStore();
  const [context_menu_open, set_context_menu_open] = useState(false);
  const [context_menu_position, set_context_menu_position] = useState({ x: 0, y: 0 });

  const sizeToBorderRadius = {
    small: 4,
    medium: 6,
    large: 8,
  };

  const borderRadius = sizeToBorderRadius[size] || 0;

  const handle_overlay = show => {
    if (features?.card_action_overlay && !multi_select && set_active_card) {
      set_active_card(material?.id);
    }
  };

  const handle_click = () => {
    if (features?.card_action_overlay) {
      set_active_modal('material');
      set_modal_data({ material });
    }
  };

  function mouse_enter_card() {
    handle_overlay(true);
  }
  function mouse_leave_card() {
    if (set_active_card) {
      set_active_card(undefined);
    }
  }

  function handle_right_click(event) {
    event.preventDefault();
    event.stopPropagation();
    set_context_menu_open(true);
    set_context_menu_position({ x: event.clientX, y: event.clientY });
  }

  return (
    <Card
      key={material?.id}
      data-testid={material?.id}
      style={{
        padding: 0,
        borderRadius: borderRadius,
        cursor: features?.card_action_overlay ? 'pointer' : 'auto',
      }}
      padding={0}
      className="material-card"
      onMouseEnter={mouse_enter_card}
      onMouseLeave={mouse_leave_card}
      onContextMenu={handle_right_click}
    >
      <>
        {selected || multi_select ? (
          <div
            className={
              selected ? 'material-card__overlay material-card__selected' : 'material-card__overlay'
            }
          ></div>
        ) : undefined}
        <div className="material-card__header">
          {features?.card_action_overlay && active && !multi_select && (
            <div
              onClick={handle_click}
              className={`material-card__overlay-active ${
                appearance === 'dark' ? 'eds--dark' : 'eds--light'
              }`}
            >
              {show_card_actions && (
                <MaterialActions
                  material={material}
                  actions={actions}
                  show_card_actions={show_card_actions}
                  appearance={appearance}
                  palette_edit_access={palette_edit_access}
                  onMaterialAction={onUpdateMaterial}
                />
              )}
            </div>
          )}
          <div className="material-card__header-contents">
            <div className="pebble-range-container">
              <ColorWindowPebbleRange material={material} />
            </div>
            <MaterialImage
              material={material}
              view_type={view_type}
              on_container_click={handle_click}
            />
          </div>
        </div>
        {material_body({ material_data, material, on_click: handle_click })}
        {!features?.card_action_overlay && (
          <div className="eds-spacing--p-12 material-card__footer">
            <Button variant="ghost" size="small" onClick={() => actions?.open(material?.id)}>
              Open
            </Button>
            {/* <Button variant="ghost" size='small'>Add To...</Button> */}
          </div>
        )}
      </>
      <MaterialCardContextMenu
        is_open={context_menu_open}
        set_is_open={set_context_menu_open}
        material={material}
        position={context_menu_position}
      />
    </Card>
  );
}

function MaterialCardBody({ material, material_data, on_click }) {
  const { active_engine } = use_engine_store();

  const ppsItem = material?.material?.suppliedMaterials?.find(
    item => item.ppsItemNumber !== undefined
  );

  const prefixValue = (item, value) => {
    if (item.id === 'pcx_number' && item.checked) {
      return `PCX ${value}`;
    } else if (
      item.display_name === 'PPS/PDM/Flex Number' &&
      item.checked &&
      active_engine === 'APPAREL'
    ) {
      if (ppsItem && ppsItem.hasOwnProperty('ppsItemNumber') && ppsItem.ppsItemNumber) {
        return `PPS ${ppsItem.ppsItemNumber}`;
      } else {
        return null;
      }
    } else if (
      item.display_name === 'PPS/PDM/Flex Number' &&
      item.checked &&
      active_engine === 'FOOTWEAR'
    ) {
      return `PDM ${value}`;
    } else if (
      item.display_name === 'PPS/PDM/Flex Number' &&
      item.checked &&
      active_engine === 'ACCESSORIES'
    ) {
      return `FLEX ${value}`;
    }
    return value;
  };

  return (
    <div className="material-card__body" onClick={on_click}>
      <div
        className={
          material?.hasMaterial3dAsset ? 'material-card__text-partial' : 'material-card__text-full'
        }
      >
        {Object.values(material_data)
          .sort((a, b) => a.order - b.order)
          .map(item => {
            if (item.checked) {
              // eslint-disable-next-line no-eval
              const get_material_data = eval(item?.get_material_data);
              if (!material) {
                return undefined;
              }
              let value = get_material_data(material);
              if (value?.length) {
                value = prefixValue(item, value);
                if (item.id === 'material_name' || item.id === 'description') {
                  return (
                    <HoverText
                      key={`${material.id}_${item.id}_hover`}
                      dataTip={true}
                      dataFor={`${material.id}_${item.id}`}
                      text={value}
                    />
                  );
                } else {
                  return (
                    <Text font={'legal-1'} key={`${material?.id}_${item.id}_text`}>
                      {value}
                    </Text>
                  );
                }
              } else {
                // filler in case there's no data when we'd expect there to be
                // allows us to maintain a consistent card height
                return (
                  <div
                    className="truncate"
                    style={{ visibility: 'hidden' }}
                    key={`${material?.id}_${item?.id}_hidden`}
                  >
                    No Data Available for {item.display_name}
                  </div>
                );
              }
            }

            return undefined;
          })}
      </div>
      {material?.hasMaterial3dAsset ? (
        <ReactSVG
          src={three_dimensional_icon}
          className="material-card__3d"
          data-testid={`${material?.id}_hasMaterial3dAsset`}
        />
      ) : undefined}
    </div>
  );
}

function MaterialCardContextMenu({ material, is_open, set_is_open, position }) {
  const { send_notification } = useNotificationsStore();
  const { active_engine } = use_engine_store();
  const { label, value } = get_secondary_material_id(material, active_engine);

  const copy = (text, key) => {
    track({ eventName: 'copyText', fieldName: key });

    if (text) {
      navigator.clipboard.writeText(text);
      send_notification('success', 'Copied to Clipboard');
    } else {
      send_notification('error', 'Material data not available');
    }
    set_is_open(false);
  };

  const header = (
    <Text font="subtitle-2" color="grey-2">
      Copy
    </Text>
  );

  const body = (
    <>
      {material?.id && (
        <MenuItem onClick={() => copy(`PCX ${material?.id}`, 'pcxNumber')}>PCX Number</MenuItem>
      )}
      {value && (
        <MenuItem onClick={() => copy(`${label} ${value}`, `${label?.toLowerCase()}Number`)}>
          {label} Number
        </MenuItem>
      )}
      {material?.name && (
        <MenuItem onClick={() => copy(material?.name, 'materialName')}>Material Name</MenuItem>
      )}
      {material?.material?.description && (
        <MenuItem onClick={() => copy(material?.material?.description, 'materialDescription')}>
          Material Description
        </MenuItem>
      )}
    </>
  );

  return (
    <FixedContextMenu
      headerSlot={header}
      bodySlot={body}
      isOpen={is_open}
      onClickOutside={() => set_is_open(false)}
      position={position}
      isDark={true}
    />
  );
}
