import { useNavigate } from 'react-router-dom';
import useColorScheme from '../../hooks/use_color_scheme';
import { Icon, Text, ButtonGroup, Button } from '@nike/eds';

import './empty_board.css';

export default function EmptyBoard({ hasWriteAccess }) {
  const { appearance } = useColorScheme();
  const navigate = useNavigate();
  const dark = appearance === 'dark';

  return (
    <div>
      <div className={`page ${dark ? 'eds--dark' : 'eds--light'}`}>
        <div className="empty-board-page page-contents">
          <Icon size="l" name="AlertCircle" />
          <Text className="empty-board__header" font={'title-5'} as={'h1'}>
            This board is empty
          </Text>

          {hasWriteAccess && (
            <>
              <Text className="empty-board-page__body" font={'body-3'} as={'p'}>
                Once materials are added to this board, they will show up here. <br />
                You can browse the library and add materials one-by-one as you <br />
                find them, or, you can multi-select materials and add them in <br />
                groups to this board. To get started click the button below.
              </Text>
              <ButtonGroup className="new-board-modal__footer eds-spacing--mt-24">
                <Button onClick={() => navigate('/library', { replace: true })}>
                  Browse Library
                </Button>
              </ButtonGroup>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
