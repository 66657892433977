import { useState } from 'react';
import { Button, ButtonGroup, Checkbox, Modal, Text, Tab, TabGroup } from '@nike/eds';
import { useBlocker } from 'react-router-dom';

import useColorScheme from '../../hooks/use_color_scheme';
import { useNotificationsStore } from '../../hooks/use_notifications';
import { getMaterialIds, getView, toMaterialData, useFiles } from '../../hooks/use_files';
import { useUploadStore } from '../../hooks/use_upload';
import { UploadQueue } from '../../components/upload_queue/upload_queue';
import ImportFile from '../../components/import_file/import_file';

import './upload.css';
import { Header } from '../../components/header';
import { fetch_all_materials_by_ids } from '../../hooks/use_materials';
import FileRow from '../../components/file_row';
import config from '../../config';

import http_client from '../../utils/fetch';
import { UploadHistory } from '../../components/upload_history/upload_history';

const disableEventPropagation = e => {
  e.preventDefault();
  e.stopPropagation();
};

function getContentType(file) {
  if (['mp4', 'MP4'].includes(file.fileType)) {
    return 'video/mp4';
  } else if (['png', 'PNG'].includes(file.fileType)) {
    return 'image/png';
  } else if (['jpg', 'jpeg', 'JPG', 'JPEG'].includes(file.fileType)) {
    return 'image/jpeg';
  }
}

async function createNewAsset(assetData, assetClass) {
  const url = `${config.api_base}${config.apis.via.assets}/${assetClass}`;
  return await http_client({
    url,
    method: 'post',
    data: assetData,
  });
}

async function updateExistingAsset(assetData, assetId, versionId, assetClass) {
  const url = `${config.api_base}${config.apis.via.assets}/${assetClass}/${assetId}/versions/${versionId}`;
  return await http_client({
    url,
    method: 'post',
    data: assetData,
  });
}

async function uploadFiles(filesToUpload, setIsUploading, uploadingStatuses, setUploadingStatuses) {
  const results = await Promise.allSettled(
    filesToUpload.map(async (f, index) => {
      const asset = f.material;

      const res = await http_client({
        url: `${config.api_base}${config.apis.via.artifacts}`,
        method: 'post',
        data: {
          artifactClass: 'generic',
          schemaVersion: '1',
          name: asset ? asset.name : f.fullName,
          fileType: f.fileType,
          contentType: getContentType(f),
          metadata: {
            viewType: 'Original',
          },
        },
      });

      const uploadedArtifact = res.data;
      const locationToPut = uploadedArtifact.location;

      await fetch(locationToPut, {
        method: 'put',
        body: f.file,
      }).then(res => res.body);

      const assetId = asset?.assetId;
      const versionId = asset?.versionId;
      const assetClass =
        getView(f.viewType, f.fileType) === 'Video' ? 'materialvideo' : 'material2d'; // we can use the assetClass from the asset but since we need some logic for create we might as well use it for updating an asset as well
      const links = [
        {
          objectId: f.pcxMaterialId,
          objectType: 'material',
        },
      ].filter(l => l.objectId);

      const existingAssetData = {
        name: asset ? asset.name : f.fullName,
        artifactIds: [uploadedArtifact.id],
        active: true,
        versionMetadata: {
          viewType: getView(f.viewType, f.fileType),
          subClass: 'vml',
        },
        versionLinks: links,
      };

      const newAssetData = {
        name: f.fullName,
        artifactIds: [uploadedArtifact.id],
        assetLinks: links,
        assetMetadata: {
          viewType: getView(f.viewType, f.fileType),
          subClass: 'vml',
        },
      };

      if (assetId) {
        updateExistingAsset(existingAssetData, assetId, versionId, assetClass);
      } else {
        createNewAsset(newAssetData, assetClass);
      }
      setIsUploading(false);
      const newUploadingStatuses = [...uploadingStatuses];
      newUploadingStatuses[index] = false;
      setUploadingStatuses(newUploadingStatuses);
    })
  );
  return results;
}

export default function Upload({ title }) {
  const [fileQueue, setFileQueue] = useState([]);
  const [validUploadedFiles, setValidUploadedFiles] = useState([]);
  const [showIndicator, setShowIndicator] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [selectAll, setSelectAll] = useState(true);
  const [uploadingStatuses, setUploadingStatuses] = useState([]);
  const [active_tab, set_active_tab] = useState('queue');
  const { send_notification } = useNotificationsStore();
  const { appearance } = useColorScheme();
  const { showBlockerModal, setShowBlockerModal } = useUploadStore();

  // Block navigating elsewhere if the user is currently uploading a file
  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    // if the user tries to navigate away from the page & we are currently uploading a file, we want to block them first and ask if they're sure
    return nextLocation?.pathname !== currentLocation?.pathname && isUploading && showBlockerModal;
  });

  const dark = appearance === 'dark';

  const { setFiles } = useFiles();

  const handleSelect = (index, isSelected) => {
    if (isSelected) {
      setSelectedFiles(prevSelectedFiles => [...prevSelectedFiles, index]);
    } else {
      setSelectedFiles(prevSelectedFiles =>
        prevSelectedFiles.filter(fileIndex => fileIndex !== index)
      );
    }
  };

  const handleSelectAll = isSelected => {
    setSelectAll(isSelected);
    if (isSelected) {
      setSelectedFiles(fileQueue?.map((_, index) => index));
    } else {
      setSelectedFiles([]);
    }
  };

  const handleDelete = index => {
    setFileQueue(fileQueue.filter((file, i) => i !== index));
    setSelectedFiles(prevSelectedFiles =>
      prevSelectedFiles
        .filter(fileIndex => fileIndex !== index)
        .map(fileIndex => (fileIndex > index ? fileIndex - 1 : fileIndex))
    );
  };

  const fileToFileRow = (file, index) => {
    const uploadStatus = file.uploadStatus;
    return (
      <FileRow
        key={`${index}-${selectedFiles.includes(index)}`}
        id={file.isValid ? file.pcxMaterialId : file.fullName}
        engine={file.materialType3}
        type={file.materialType4}
        subType={file.materialType5}
        existing={false}
        materialName={file.isValid ? file.fullName?.split('_')[0] : file.fullName}
        view={getView(file.viewType, file.fileType)}
        uploadStatus={uploadStatus}
        textColor={file.textColor}
        isValid={file.isValid}
        existingMaterial={file.exists}
        onDelete={() => handleDelete(index)}
        onSelect={isSelected => handleSelect(index, isSelected)}
        selected={selectedFiles.includes(index)}
        isUploading={isUploading}
      />
    );
  };

  const handleClearAll = () => {
    setFileQueue(prevFileQueue => {
      setSelectedFiles([]);
      return [];
    });
  };

  const storeNewFiles = async newFiles => {
    setFiles(newFiles);
    setFileQueue([...fileQueue, ...newFiles]);
  };

  const handleDrop = e => {
    disableEventPropagation(e);
    handleFileImport(e.dataTransfer.files);
  };

  const handleFileImport = async files => {
    const newFiles = Array.from(files);

    const materialIds = getMaterialIds(newFiles);
    const allMaterials = await fetch_all_materials_by_ids(materialIds);

    const existingIds = materialIds.filter(id => allMaterials.some(m => m.id === id));
    const materialsMap = new Map(allMaterials.map(m => [m.id, m]));
    const allImportedFiles = newFiles.map(f => toMaterialData(f, materialsMap, existingIds));
    setSelectedFiles([...selectedFiles, ...newFiles.map((f, i) => i + selectedFiles.length)]);

    storeNewFiles([...allImportedFiles]);
  };

  const handleUpload = async () => {
    setIsUploading(true);
    setUploadingStatuses(fileQueue?.map(() => true));
    try {
      setIsUploading(true);
      const selectedForUpload = fileQueue.filter((f, i) => selectedFiles.includes(i));
      const validNewFiles = selectedForUpload.filter(f => !f.material).filter(f => f.isValid);
      setValidUploadedFiles(validNewFiles);

      const existingArtifacts = selectedForUpload
        .filter(f => f.material)
        .map(f => {
          const reference = f.material.artifactReferences.find(a => {
            return a.name === f.file.name;
          });

          return { ...reference, ...f };
        })
        .flat();

      const results = await uploadFiles(
        [...existingArtifacts, ...validNewFiles],
        setIsUploading,
        uploadingStatuses,
        setUploadingStatuses
      );

      const failedCount = results.filter(r => r.status === 'rejected').length;

      let message = '';
      let status = '';

      if (failedCount === 0 && validNewFiles.length === 0) {
        message = `File(s) were successfully uploaded.`;
        status = 'success';
      } else if (failedCount === 0 && validNewFiles.length > 0) {
        message = `Upload of ${validNewFiles.length} Assets was successful.`;
        status = 'success';
      } else if (failedCount > 0 && validNewFiles.length === 0) {
        message = `Upload of ${failedCount} files was unsuccessful.`;
        status = 'error';
      } else if (failedCount > 0 && validNewFiles.length > 0) {
        message = `Upload of ${validNewFiles.length} Assets was successful. ${failedCount} Assets was Unsuccessful. Please try again.`;
        status = 'warning';
      }

      send_notification(status, message);
      if (validNewFiles.length > 0) {
        setShowIndicator(true);
      }

      setFileQueue([]);
    } catch (error) {
      console.error(`Error uploading file:`, error.message);
      setIsUploading(false);

      send_notification('error', 'Error Uploading File');
    }
  };

  const handleNavigationBlockingModal = checked => {
    if (checked) {
      setShowBlockerModal(false);
    } else {
      setShowBlockerModal(true);
    }
  };

  return (
    <div className={`uploads page ${dark ? 'eds--dark' : 'eds--light'}`}>
      <div className="page-header">
        <Header
          className="custom-header"
          header_top={() => (
            <div className="upload-header eds-spacing--mt-36">
              <div className="header-title truncate">
                <Text font="title-4" as={'h1'} className="truncate">
                  {title}
                </Text>
              </div>
              <div className="truncate eds-spacing--mt-8 ">
                <Text font="body-3" className="truncate">
                  Click to upload or drag and drop files into the queue to start the upload process
                </Text>
              </div>
            </div>
          )}
          show_search={false}
          header_bottom={() => (
            <div className="tab-group">
              <TabGroup
                className="eds-spacing--mb-24 eds-spacing--mt-24"
                name="uploads"
                activeId={active_tab}
                onChange={e => {
                  set_active_tab(e.target.id);
                  if (e.target.id === 'history') {
                    setShowIndicator(false);
                  }
                }}
              >
                <Tab id="queue">Queue</Tab>
                {showIndicator && (
                  <div className="recent-uploads-indicator">{validUploadedFiles.length}</div>
                )}
                <Tab id="history">History</Tab>
              </TabGroup>
            </div>
          )}
          show_tote={false}
          icon_row={{ filter: false, sort: false, select: false, display_settings: false }}
        />
      </div>
      <div className="page-contents">
        {active_tab === 'queue' ? (
          <>
            <Modal
              isOpen={blocker.state === 'blocked'}
              onDismiss={blocker.reset}
              headerSlot={'Upload in Progress'}
              footerSlot={
                <ButtonGroup>
                  <Button variant="primary" onClick={blocker.proceed}>
                    Continue
                  </Button>
                  <Button variant="secondary" onClick={blocker.reset}>
                    Stay Here
                  </Button>
                </ButtonGroup>
              }
            >
              <div className="upload-modal__body">
                <div>
                  <span className="eds-type--body-2">
                    As you navigate away from the Upload page, your upload queue will continue to
                    process
                  </span>
                  <span className=" bold-text eds-type--subtitle-1">
                    {''} as long as you do not close Material Hub.
                  </span>
                </div>
                <p className="eds-type--body-2 eds-spacing--mt-24">
                  Once the upload is complete, you will be notified.
                </p>
                <div className="eds-spacing--mt-24">
                  <Checkbox
                    checked={false}
                    onChange={e => handleNavigationBlockingModal(e.target.checked)}
                    label="Don't show this message again"
                  />
                </div>
              </div>
            </Modal>

            <div
              className="upload-queue"
              onDrop={handleDrop}
              onDragOver={disableEventPropagation}
              onDragEnter={disableEventPropagation}
              onDragLeave={disableEventPropagation}
            >
              <div className="upload-queue--info eds-spacing--mb-36">
                <div className="upload-queue--info-context eds-spacing--mt-36">
                  <Text font="title-6">Upload Queue</Text>
                  <Text font="body-3" color="secondary">
                    Supported file types: PNG, JPG, MP4
                  </Text>
                </div>
                <div className="upload-queue--info-action">
                  {fileQueue.length ? (
                    <div className="eds-spacing--mb-8">
                      <ImportFile onChange={handleFileImport} />
                    </div>
                  ) : null}
                  <Text font="body-3" color="secondary">
                    Maximum file size: 100mb
                  </Text>
                </div>
              </div>
              <UploadQueue
                handleFileImport={handleFileImport}
                fileQueue={fileQueue}
                handleSelectAll={handleSelectAll}
                selectAll={selectAll}
                handleClearAll={handleClearAll}
                handleUpload={handleUpload}
                fileToFileRow={fileToFileRow}
                selectedFiles={selectedFiles}
              />
            </div>
          </>
        ) : (
          <div>
            <UploadHistory />
          </div>
        )}
      </div>
    </div>
  );
}
