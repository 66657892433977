import { OktaAuth } from '@okta/okta-auth-js';
import config from '../config';

export const okta_auth = new OktaAuth({
  issuer: config.issuer,
  clientId: config.client_id,
  redirectUri: `${config.base_url}/implicit/callback`,
  scopes: [
    'default',
    'openid',
    'profile',
    'offline_access',
    'email',
    'product_design:aurora.gateway.internal::read:',
    'product_design:aurora.search.allmaterials.v1::read:',
    'product_design:aurora.search.paletteentries.v2::read:',
    'product_design:aurora.search.palettesummaries.v1::read:',
    'product_design:aurora.search.allassets.v2::read:',
  ],
  pkce: true,
  responseType: ['id_token', 'token', 'refresh_token'],
  tokenManager: {
    autoRenew: true,
    autoRemove: true,
    syncStorage: true,
    expireEarlySeconds: 120, // 2 minutes in total seconds only in DEV environment
  },
  services: {
    autoRenew: true,
    autoRemove: true,
    syncStorage: true,
    renewOnTabActivation: true,
    tabInactivityDuration: 28800, // 8 hours in total seconds
  },
  // devMode: true, // uncomment to enable dev mode and see events logged
});
