import ReactGA from 'react-ga4';
import gtag from '../plugins/gtag';
import { create } from '@dpc/platform-client-logger';
import packageJson from '../../package.json';
import { use_auth_store } from '../hooks/use_auth';
import { use_engine_store } from '../hooks/use_engine';
import config from '../config';

const { user } = use_auth_store.getState();
const { active_engine } = use_engine_store.getState();

export default function sendEvent(eventName, data) {
  const gaTag = gtag;

  ReactGA.initialize(gaTag);

  // Send pageview with a custom path
  ReactGA.send({ hitType: 'pageview', page: '/my-path', title: 'Custom Title' });

  // Send a custom event
  ReactGA.event();

  if (gtag) {
    console.log('GTAG EVENT ', {
      eventName,
      ...{
        ...data,
      },
    });
    gtag.event(eventName, {
      ...data,
    });
  } else {
    console.warn('Google Analytics not set up');
  }
}

const local = process.env.NODE_ENV === 'development';
const okta_token_storage = JSON.parse(localStorage.getItem('okta-token-storage'))?.accessToken;
const params = {
  // key/values needed to properly send the log
  appVersion: packageJson.version,
  appName: 'dpc/materialHubLibrary',
  apiBase: config.api_base,
  webStream: true,
  getToken: () => okta_token_storage?.accessToken,
};

// we're moving the create method invocation inside all the analytics functions to ensure that the okta token is always up to date

export function track(event, msg) {
  if (local) {
    console.log('TRACK EVENT ', event);
  }
  const log = create(params);

  log.info(
    {
      session: user?.session,
      userAccessLevel: user?.groups,
      token: okta_token_storage?.accessToken,
      user: user?.preferred_username,
      engine: active_engine.toLowerCase(),
      page: window?.location?.pathname,
      ...event,
    },
    msg
  );
}

// Used for logging events we think might be useful for debugging
export function debug(event, msg) {
  if (local) {
    console.log('DEBUG EVENT ', event);
  }
  const log = create(params);

  log.debug(
    {
      session: user?.session,
      userAccessLevel: user?.groups,
      token: okta_token_storage?.accessToken,
      user: user?.preferred_username,
      engine: active_engine.toLowerCase(),
      page: window?.location?.pathname,
      ...event,
    },
    msg
  );
}

// Used for logging errors, usually on the end of a try/catch block
export function warn(event, msg) {
  if (local) {
    console.log('WARN EVENT ', event);
  }
  const log = create(params);

  log.warn(
    {
      session: user?.session,
      userAccessLevel: user?.groups,
      token: okta_token_storage?.accessToken,
      user: user?.preferred_username,
      engine: active_engine.toLowerCase(),
      page: window?.location?.pathname,
      ...event,
    },
    msg
  );
}
